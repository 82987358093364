<template>
  <div>
    <Toolbar class="mb-4">
      <template #left>
        <Button
          icon="pi pi-times"
          class="p-button-danger mr-2"
          @click="$router.push('/admin/cat_producat')"
        />
        <Button icon="pi pi-print" @click="printPage()" label="Print" />

        <!-- <Button
          class="p-button-help ml-2"
          @click="
            $router.push('/admin/users/years/' + id + '/' + $byYear(month))
          "
          label="Year Report"
          v-if="month"
        /> -->
      </template>

      <template #right>
        <Calendar v-model="month" view="month" dateFormat="mm/yy" />
      </template>
    </Toolbar>
    <div id="print" v-if="month && list.length > 0">
      <DataTable
        ref="dt"
        :value="list"
        class="p-datatable-gridlines p-datatable-sm"
        showGridlines
        dataKey="day"
        :rowHover="true"
      >
        <template #empty>Keine Daten</template>
        <template #loading>Daten werden geladen, bitte warten... </template>

        <ColumnGroup type="header">
          <Row>
            <Column header="Artikelbezeichnung und Mengenkonditionen" />
            <Column
              v-for="item of producatList"
              :key="'item_name_day_' + item.id"
              :header="item.name"
              :colspan="2"
            />
          </Row>
          <Row>
            <Column header="Art.-Nr." />
            <Column
              v-for="item of producatList"
              :key="'item_art_day_' + item.id"
              :header="item.code"
              :colspan="2"
            />
          </Row>

          <Row>
            <Column header="EK-Preis EUR*" />
            <Column
              v-for="item of producatList"
              :key="'item_ek_day_' + item.id"
              :header="item.purchasing"
              :colspan="2"
            />
          </Row>

          <Row>
            <Column header="VK-Preis EUR**" />
            <Column
              v-for="item of producatList"
              :key="'item_vk_day_' + item.id"
              :header="item.price"
              :colspan="2"
            />
          </Row>

          <Row>
            <Column header="Inhalt" />
            <Column
              v-for="item of producatList"
              :key="'item_inhalt_day_' + item.id"
              :header="item.size + 'ml'"
              :colspan="2"
            />
          </Row>

          <Row>
            <Column header="" field="day" />
            <template v-for="item of producatList">
              <Column
                :key="'item_buying_day_' + item.id"
                :field="'item_buying_' + item.id"
                header="Einkauf"
              />
              <Column
                :key="'item_selling_day_' + item.id"
                :field="'item_selling_' + item.id"
                header="Verkauf"
              />
            </template>
          </Row>
        </ColumnGroup>
        <Column field="day" header="Datum" />
        <template v-for="item of producatList">
          <Column
            :key="'item_buying_' + item.id"
            :field="'item_buying_' + item.id"
            header="Einkauf"
          >
            <template #body="slotProps">
              <p v-if="slotProps.data['item_buying_' + item.id] > 0">
                {{ slotProps.data['item_buying_' + item.id] }}
              </p>
            </template>
          </Column>
          <Column
            :key="'item_selling_' + item.id"
            :field="'item_selling_' + item.id"
            header="Verkauf"
          >
            <template #body="slotProps">
              <p v-if="slotProps.data['item_selling_' + item.id] > 0">
                {{ slotProps.data['item_selling_' + item.id] }}
              </p>
            </template>
          </Column>
        </template>

        <ColumnGroup type="footer">
          <Row>
            <Column footer="Summe" :footerStyle="{ 'text-align': 'left' }" />

            <template v-for="item of producatList">
              <Column
                :key="'item_buying_f_' + item.id"
                :footer="getTotal(item.id, 'b')"
              />
              <Column
                :key="'item_selling_f_' + item.id"
                :footer="getTotal(item.id, 's')"
              />
            </template>
          </Row>

          <Row>
            <Column footer="Bestand" :footerStyle="{ 'text-align': 'left' }" />
            <Column
              v-for="item of producatList"
              :key="'item_selling_sub_' + item.id"
              :footer="getTotal(item.id, 's') - getTotal(item.id, 'b')"
              :colspan="2"
            />
          </Row>
          <Row>
            <Column footer="Gesamt €" />
            <template v-for="item of producatList">
              <Column
                :key="'item_buying_f_2_' + item.id"
                :footer="getTotalPrice(item.id, 'b')"
              />
              <Column
                :key="'item_selling_f_2_' + item.id"
                :footer="getTotalPrice(item.id, 's')"
              />
            </template>
          </Row>
        </ColumnGroup>
      </DataTable>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  data() {
    return {
      list: [],
      month: null,
      id: null,
      producatList: [],
      listDay: [],
      $buying: [],
      $selling: [],
    };
  },
  methods: {
    getTotal(id, t) {
      let total = 0;
      if (t == 'b') {
        total = this.$buying
          .filter((el) => el.itemId == id)
          .map((el) => el.quantity)
          .reduce((acc, sum) => acc + sum, 0);
      } else {
        total = this.$selling
          .filter((el) => el.itemId == id)
          .map((el) => el.quantity)
          .reduce((acc, sum) => acc + sum, 0);
      }
      return total;
    },
    getTotalPrice(id, t) {
      let total = 0;
      if (t == 'b') {
        total = this.$buying
          .filter((el) => el.itemId == id)
          .map((el) => el.itemPrice)
          .reduce((acc, sum) => acc + sum, 0);
      } else {
        total = this.$selling
          .filter((el) => el.itemId == id)
          .map((el) => el.price)
          .reduce((acc, sum) => acc + sum, 0);
      }
      return total;
    },
    printPage() {
      // Get HTML to print from element
      const prtHtml = document.getElementById('print').innerHTML;
      // Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }
      // Open the print window
      const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,width=1024,height=900,toolbar=0,scrollbars=0,status=0',
      );
      WinPrint.document.write(this.$htmlPrint(stylesHtml, prtHtml, ``));
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },

    getDays(val) {
      const monthDays = [];
      const month = moment(val, 'MM-YYYY').daysInMonth();

      for (let index = 0; index < month; index++) {
        const duration = moment(val, 'MM-YYYY').add(index, 'days');

        const day = duration.format('DD-MM-YYYY');
        const dt = moment(day, 'DD-MM-YYYY');
        const dayName = dt.format('dddd').substring(0, 3);
        monthDays.push({
          day,
          tag: dayName,
        });
      }

      this.listDay = monthDays;
    },
    getData(val) {
      const start = moment(this.listDay[0].day, 'DD-MM-YYYY').format(
        'DD-MM-YYYY',
      );
      const end = moment(
        this.listDay[this.listDay.length - 1].day,
        'DD-MM-YYYY',
      ).format('DD-MM-YYYY');

      this.$http.get(`getReport/${start}/${end}`).then(
        (response) => {
          this.$buying = response.data.buying;
          this.$selling = response.data.selling;

          this.list = [];
          for (let item of this.listDay) {
            for (const itm of this.producatList) {
              item[`item_buying_${itm.id}`] = this.$buying
                .filter((el) => el.itemId == itm.id)
                .filter(
                  (el) =>
                    moment(el.date, 'YYYY-MM-DD').format('DD-MM-YYYY') ==
                    item.day,
                )
                .map((el) => el.quantity)
                .reduce((acc, sum) => acc + sum, 0);
              item[`item_selling_${itm.id}`] = this.$selling
                .filter((el) => el.itemId == itm.id)
                .filter(
                  (el) =>
                    moment(el.date, 'YYYY-MM-DD').format('DD-MM-YYYY') ==
                    item.day,
                )
                .map((el) => el.quantity)
                .reduce((acc, sum) => acc + sum, 0);
            }
            this.list.push({ ...item });
          }
        },
        (err) => {
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.$http.get(`products?categoriesId=${this.id}`).then(
      (res) => {
        this.producatList = res.data.data;
      },
      (err) => {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: err.response.data.message,
          life: 3000,
        });
      },
    );
  },
  watch: {
    month(val) {
      if (val) {
        this.getDays(val);
        this.getData(val);
      }
    },
  },
};
</script>

<style>
.hideHead .p-datatable-thead {
  display: none;
}
.p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
  text-align: center;
}
</style>
